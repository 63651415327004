// @ts-nocheck
import {
  getEstimate,
  getExpenses,
  getExpensesSums,
  getOutlays,
} from "@/modules/finances/services/finances.services";
import {
  IEstimate,
  IExpenses,
  IOutlay,
} from "@/modules/finances/types/finances.types";

export default {
  namespaced: true,
  state: {
    outlays: [] as IOutlay[],
    expenses: [] as IExpenses[],
    estimate: {} as IEstimate,
    currentAmount: 0,
    expectedAmount: 0,
    sum: 0,
    outlaySum: 0,
  },
  mutations: {
    setOutlaySum(state: any, payload: any) {
      state.outlaySum = payload.sum;
    },
    setOutlays(state: any, payload: any) {
      state.outlays = payload.outlays
        .map((outlay: any) => {
          outlay.items = outlay.items
            .sort((a: any, b: any) => a.title.localeCompare(b.title))
            .map((item: any) => {
              return {
                ...item,
                isEditingCost: false,
                isEditingAmount: false,
              };
            });

          return {
            ...outlay,
          };
        })
        .sort((a: any, b: any) => a.title.localeCompare(b.title));
    },
    turnOffOutlayEditCost(state: any, { outlayId, itemId }: any) {
      const outlay = state.outlays.find(
        (outlay: any) => outlay.id === outlayId
      );
      const item = outlay.items.find((item: any) => item.id === itemId);
      item.isEditingCost = false;
    },
    turnOffOutlayEditAmount(state: any, { outlayId, itemId }: any) {
      const outlay = state.outlays.find(
        (outlay: any) => outlay.id === outlayId
      );
      const item = outlay.items.find((item: any) => item.id === itemId);
      item.isEditingAmount = false;
    },
    setOutlayEditingCost(state: any, { outlayId, itemId }: any) {
      const outlay = state.outlays.find(
        (outlay: any) => outlay.id === outlayId
      );
      const item = outlay.items.find((item: any) => item.id === itemId);
      state.outlays.forEach((outlay: any) => {
        outlay.items.forEach((item: any) => {
          item.isEditingCost = false;
          item.isEditingAmount = false;
        });
      });
      item.isEditingCost = true;
    },
    setOutlayEditingAmount(state: any, { outlayId, itemId }: any) {
      const outlay = state.outlays.find(
        (outlay: any) => outlay.id === outlayId
      );
      const item = outlay.items.find((item: any) => item.id === itemId);
      state.outlays.forEach((outlay: any) => {
        outlay.items.forEach((item: any) => {
          item.isEditingAmount = false;
          item.isEditingCost = false;
        });
      });
      item.isEditingAmount = true;
    },
    turnOffExpensesEditing(state: any) {
      state.expenses = state.expenses
        .map((mainExpense: any) => {
          mainExpense.items = mainExpense.items
            .sort((a: any, b: any) => a.title.localeCompare(b.title))
            .map((item: any) => {
              item.subItems = item.subItems
                .sort((a: any, b: any) => a.title.localeCompare(b.title))
                .map((subItem: any) => {
                  return {
                    ...subItem,
                    isEditing: false,
                  };
                });
              return {
                ...item,
              };
            });
          return {
            ...mainExpense,
          };
        })
        .sort((a: any, b: any) => a.title.localeCompare(b.title));
    },
    resetExpenses(state: any, payload: any) {
      const sortedPayload = [
        ...payload.sort((a, b) => a.title.localeCompare(b.title)),
      ];
      state.expenses = sortedPayload
        .map((mainExpense: any, mainExpenseIndex: any) => {
          mainExpense.items = mainExpense.items
            .sort((a: any, b: any) => a.title.localeCompare(b.title))
            .map((item: any, itemIndex: any) => {
              item.subItems = item.subItems
                .sort((a: any, b: any) => a.title.localeCompare(b.title))
                .map((subItem: any, subItemIndex: any) => {
                  return {
                    ...subItem,
                    isVisible: false,
                    isEditing: false,
                    editingText: "",
                  };
                });

              return {
                ...item,
                isVisible:
                  state.expenses[mainExpenseIndex].items[itemIndex].isVisible,
              };
            });
          return {
            ...mainExpense,
            isVisible: state.expenses[mainExpenseIndex].isVisible,
          };
        })
        .sort((a: any, b: any) => a.title.localeCompare(b.title));
    },
    setExpenses(state: any, payload: any) {
      state.expenses = payload
        .map((mainExpense) => {
          mainExpense.items = mainExpense.items
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((item) => {
              item.subItems = item.subItems
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((subItem) => {
                  return {
                    ...subItem,
                    isVisible: false,
                    isEditing: false,
                    editingText: "",
                  };
                });
              return {
                ...item,
                isVisible: false,
                isEditing: false,
              };
            });
          return {
            ...mainExpense,
            isVisible: false,
          };
        })
        .sort((a, b) => a.title.localeCompare(b.title));
    },
    setEstimate(state: any, payload: any) {
      state.estimate = payload;
    },
    setVisibleMainExpense(state: any, payload: any) {
      const mainExpense = state.expenses.find(
        (expense: any) => expense.id === payload.id
      );
      mainExpense.isVisible = !mainExpense.isVisible;
    },
    setVisibleMainExpenseItem(state: any, payload: any) {
      const mainExpense = state.expenses.find(
        (expense: any) => expense.id === payload.id
      );
      const item = mainExpense.items.find(
        (item: any) => item.id === payload.itemId
      );
      item.isVisible = !item.isVisible;
    },
    setVisibleLastItem(state: any, payload: any) {
      const mainExpense = state.expenses.find(
        (expense: any) => expense.id === payload.id
      );
      const item = mainExpense.items.find(
        (item: any) => item.id === payload.itemId
      );
      const subItem = item.subItems.find(
        (subItem: any) => subItem.id === payload.subItemId
      );
      subItem.isVisible = !subItem.isVisible;
    },
    setExpensesEditing(state: any, payload: any) {
      const mainExpense = state.expenses.find(
        (expense: any) => expense.id === payload.id
      );
      const item = mainExpense.items.find(
        (item: any) => item.id === payload.itemId
      );
      // const subItem = item.subItems.find(
      //   (subItem: any) => subItem.id === payload.subItemId
      // );
      // subItem.isEditing = payload.isEditing;
      item.isEditing = payload.isEditing;
    },
    setExpensesSecondItemEditing(state: any, payload: any) {
      const mainExpense = state.expenses.find(
        (expense: any) => expense.id === payload.id
      );
      const item = mainExpense.items.find(
        (item: any) => item.id === payload.itemId
      );

      item.isEditing = payload.isEditing;
    },
    setAmounts(state: any, payload: any) {
      state.currentAmount = payload.currentAmount;
      state.expectedAmount = payload.expectedAmount;
    },
  },
  actions: {
    async fetchOutlays(context: any, { role, partnerId, spotId }: any) {
      try {
        const response = await getOutlays(role, partnerId, spotId);
        context.commit("setOutlays", response?.data);
        context.commit("setOutlaySum", response?.data);
      } catch (e: any) {
        return e.response;
      }
    },
    async fetchExpenses(context: any, { role, partnerId, spotId }: any) {
      try {
        const response = await getExpenses(role, partnerId, spotId);
        context.commit("setExpenses", response?.data);
      } catch (e: any) {
        return e.response;
      }
    },
    async refetchExpenses(context: any, { role, partnerId, spotId }: any) {
      try {
        const response = await getExpenses(role, partnerId, spotId);
        context.commit("resetExpenses", response?.data);
      } catch (e: any) {
        console.log(e);
        return e.response;
      }
    },
    async fetchEstimate(context: any, { role, partnerId, spotId }: any) {
      try {
        const response = await getEstimate(role, partnerId, spotId);
        context.commit("setEstimate", response?.data);
      } catch (e: any) {
        console.log(e);
        return e.response;
      }
    },
    async fetchExpensesSum(context: any, { role, partnerId, spotId }: any) {
      try {
        const response = await getExpensesSums(role, partnerId, spotId);
        context.commit("setAmounts", response?.data);
      } catch (e: any) {
        return e.response;
      }
    },
  },
};
